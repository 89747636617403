/* eslint-disable */
<template>
	<div>
		<div class="timeline__post__actions">
			<button
				:class="{'active' : likedByUser}"
				class="icon-button timeline__icon timeline__thumb"
				@click="likePost">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="w-6 h-6">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
				</svg>
				<span>{{ likeCounter }}</span>
			</button>

			<button
				v-if="post.comment_status === 'open'"
				class="icon-button timeline__icon timeline__comment-action"
				@click.prevent="viewingComments = !viewingComments"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					stroke="currentColor"
					class="w-6 h-6">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
				</svg>
				<span>{{ commentCount }}</span>
				<span v-if="isAdmin && pendingCount > 0" class="timeline__post__actions__pending">( {{pendingCount}} Pending)</span>
			</button>

			<span
				v-if="isAuthor && postStatus === 'pending'"
				class="timeline__post__review">(Only visible to you)</span>

			<div class="timeline__likedby" v-if="post.liked_by && post.liked_by.length > 0">
				<div @click.prevent="showLikesAction">Liked by {{latestLikedBy}} <span v-if="post.liked_by.length > 1"> and {{(post.liked_by.length - 1)}} other{{(post.liked_by.length > 2) ? 's' : null}}</span></div>
				<Transition name="fade">
					<div class="timeline__likedby__list" v-if="showLikes">
							<a :href="'/profile/'+liker.ID" v-for="(liker,id) in post.liked_by" :key="'liked-by-'+id" class="timeline__likedby__list__user">
									<user-badge :rank="liker.rank.tier_name" :colour="liker.rank.tier_colour" :avatar="liker.avatar"></user-badge> {{liker.display_name}}
							</a>

							<button class="button timeline__likedby__list__bottom-back" @click="closeLikes">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
								</svg>
								Back to Timeline
							</button> 
					</div>
				</Transition>
			</div>
		</div>

		<Comments
			v-if="viewingComments"
			:comments="coalescedComments"
			:user="user"
			:post-id="post.ID"
			@commentsUpdated="commentsUpdated"
			@commentApproved="commentsApproved"
			@commentRejected="commentsRejected"
			@commentSetId="commentSetId"
		/>


	</div>
</template>

<script>
import Comments from '@/components/TimelineComponents/CommentsComponent';
import UserBadge from '@/components/UserBadgeComponent';

export default {
	components: {
		Comments,
		UserBadge
	},

	props: {
		post: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			likeCounter: 0,
			likedByUser: false,
			commentCount : 0,
			rejectedCount : 0,
			comments: null,
			showLikes: false,
			viewingComments: false,
		};
	},

	mounted() {
		this.likeCounter = this.post.likes;
		this.likedByUser = this.post.liked_by_user;
		this.commentCount = this.post.comment_counter.approved;

		this.$root.$on('navScrollTop', () => {
			this.showLikes = false;
		});

		window.addEventListener("hashchange", (event) => {
			if(event.oldURL.includes('#likes')) {
				this.closeLikes();
			}
		});
	},

	computed: {
		isAdmin () {
			if (this.user.role === 'editor' || this.user.role === 'administrator') {
				return true;
			}

			return false;
		},

		latestLikedBy() {
			if(this.post.liked_by && this.post.liked_by.length > 0){
				let latestUser = this.post.liked_by[this.post.liked_by.length-1];
				
				if(latestUser.ID == this.user.id && this.post.liked_by.length > 1) {
					return this.post.liked_by[this.post.liked_by.length-2].display_name;
				}

				if(latestUser.ID == this.user.id) {
					return 'You';
				}

				return	this.post.liked_by[this.post.liked_by.length-1].display_name
			}
		},

		isAuthor () {
			if (this.user.id === this.post.post_author) {
				return true;
			}

			return false;
		},

		coalescedComments () {
			return this.comments || this.post.comments;
		},

		pendingCount() {
			return this.post.comment_counter.pending - this.rejectedCount;
		}
	},

	methods: {

		commentSetId(commentId, parentId, tempId) {
			// console.log(commentId);
			// console.log(parentId);
			// console.log(this.comments);
			// console.log(this.coalescedComments)
			// console.log(tempId);
			// let index = this.comments.findIndex( (comment) => {
			// 	return comment.comment_ID == 'temp-'+tempId;	
			// });
// 
			// console.log(index);
		},

		showLikesAction() {
			this.showLikes = true;
			history.pushState(true, '', '#likes')
		},

		closeLikes() {
			this.showLikes = false;
			history.replaceState(null, null, ' ');
		},

		likePost () {
			if (this.likedByUser) {
				this.likeCounter = this.likeCounter - 1;
				this.likedByUser = false;
				gtag('event', 'click', {'event_category': 'Timeline', 'event_label': 'Unliked' + this.post.post_title});

				if(this.latestLikedBy == 'You') {
					this.post.liked_by = [];
				} else {
					let index = this.post.liked_by.findIndex( (post) => {
						return post.ID == this.user.id;	
					});

					if (index > -1) {
						this.post.liked_by.splice(index, 1);
					}

				}

			} else {
				this.likeCounter = this.likeCounter + 1;
				this.likedByUser = true;

				if(!this.post.liked_by) {
					this.post.liked_by = [];
				}
				this.post.liked_by.push({ID: this.user.id, display_name: this.user.name, rank: this.user.rank.tier_name});

				gtag('event', 'click', {'event_category': 'Timeline', 'event_label': 'Liked' + this.post.post_title});
			}

			window.axios.put('wp-json/kxi/v1/timeline/like/' + this.post.ID +'?type='+this.post.post_type);
		},

		commentsUpdated (newComment, allComments) {
			// If comment is approved
			if (newComment.comment_approved) {
				this.commentCount = parseInt(this.commentCount) + 1;
			}

			this.comments = allComments;
		},

		commentsApproved() {
			this.commentCount = parseInt(this.commentCount) + 1;
			this.rejectedCount = this.rejectedCount + 1;
		},

		commentsRejected () {
			this.rejectedCount = this.rejectedCount + 1;
		},
	},
};
</script>