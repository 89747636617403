<template>
	<div class="home container container--no-x-pad">
		<div class="home__layout">
			<TimelineComponent
				:user="currentUser"
				@openPost="openPost"/>
		</div>
	</div>
</template>

<script>

import TimelineComponent from '@/components/TimelineComponent';
import { mapState, mapActions, Time } from 'vuex';
import CookieService from '@/services/CookiesService';

export default {
	name: 'Home',

	components: {
		TimelineComponent,
	},

	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
	},

	data () {
		return {
			progress: 0,
		};
	},

	watch: {
		loaded (after, before) {
			if (after) {
				 this.triggerCounter();
			}
		},

		currentUser (after, before) {
			if (after.name) {
				window.localStorage.setItem('display_name', after.name);
			}
			// Resync Device token every 24 hours
			if (!CookieService.get('deviceTokenSynced')) {
				if (typeof Android !== 'undefined') {
					// eslint-disable-next-line no-undef
					Android.storeUser(JSON.stringify(after));
					let expire = new Date();
					expire.setHours(23, 59, 59, 0);
					CookieService.set('deviceTokenSynced', true, expire);
				}
			}
		},
  	},

	mounted () {
	},

	methods: {
		openPost (open) {
		},

	},
};
</script>
