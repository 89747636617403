<template>
	<div
		v-if="showLogo"
		class="app-header"
	>
		<router-link
			:to="{name: 'home'}"
			class="app-header__link"
		>
			<svg width="429px" height="178px" viewBox="0 0 429 178" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" alt="KXi Logo" class="app-header__logo">
				<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="S10-Plus---Login" transform="translate(-146.000000, -317.000000)" fill="currentColor">
						<g id="Logo-|-Lockup" transform="translate(111.000000, 240.000000)">
							<g id="KXi">
								<g id="KXi-Logo" transform="translate(35.647280, 77.824940)">
									<polygon id="Fill-1" points="91.0488506 0.00016474596 32.8433834 72.1770174 32.8433834 0.00016474596 0.000274704401 0.00016474596 0.000274704401 176.217386 32.8433834 176.217386 32.8433834 115.142764 51.9270981 91.4863422 98.537842 176.217386 135.965767 176.217386 75.1083037 68.5602944 130.895273 0.00016474596"></polygon>
									<polygon id="Fill-2" points="254.291501 0.00016474596 218.788704 58.6574147 183.291951 0.00016474596 146.578257 0.00016474596 199.225356 87.137598 145.370657 176.216837 182.32609 176.216837 218.788704 115.628764 255.502948 176.216837 292.206202 176.216837 238.105918 87.137598 291.240891 0.00016474596"></polygon>
									<polygon id="Fill-3" points="366.653787 104.995127 366.653787 55.6624979 345.83449 55.6624979 345.835039 65.8300694 355.224985 65.8300694 355.224436 104.995127 346.017443 104.995127 346.017993 114.88922 375.822871 114.88922 375.822871 104.995127"></polygon>
									<polygon id="Fill-4" points="355.202679 43.4319775 366.707849 43.4319775 366.707849 25.6070138 355.202679 25.6070138"></polygon>
									<path d="M360.189388,11.5059677 C391.328231,11.5059677 416.660921,37.8774027 416.660921,70.2928178 C416.660921,102.707684 391.328231,129.079119 360.189388,129.079119 C329.050546,129.079119 303.717855,102.707684 303.717855,70.2928178 C303.717855,37.8774027 329.050546,11.5059677 360.189388,11.5059677 M360.189388,0.00010983064 C322.64334,0.00010983064 292.206093,31.4709815 292.206093,70.2928178 C292.206093,109.114105 322.64334,140.585526 360.189388,140.585526 C397.735436,140.585526 428.172134,109.114105 428.172134,70.2928178 C428.172134,31.4709815 397.735436,0.00010983064 360.189388,0.00010983064" id="Fill-5"></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</router-link>


		<button @click.prevent="$root.$emit('openSearch');" class="icon-button app-header__search">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>
		</button>

		<router-link
			:to="{name: 'profile'}"
			class="app-header__profile">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="app-header__profile__icon">
  				<path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
			</svg>
		</router-link>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
	props: {
		showLogo: {
			type: Boolean,
			default: true,
			required: false,
		},
		showNotifications: {
			type: Boolean,
			default: true,
			required: false,
		},
	},

	data () {
		return {
			searching: false,
		};
	},

	computed: {
		...mapState([
			'searchOpen',
		]),
	},

	watch: {
		searchOpen () {
			this.searching = this.searchOpen;
		},
	},

};
</script>
