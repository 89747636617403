<template>
	<article class="insights__post">
		<header class="insights__post__header">
			<h1 class="insights__post__title">{{ post.post_title }}</h1>
			<p class="insights__post__author">Admin Team</p>
			<time class="insights__post__time">{{ postTime }}</time>
		</header>

		<Slideshow
			:index="imageIndex"
			:images="post.gallery"
			@closeLightbox="lightboxClosed()"
		/>

		<div class="insights__post__gallery">
			<span v-for="(image, key) in post.gallery"
				v-if="key <= (maxImagesPerGallery - 1)"
				:key="key"
			@click="insightClicked(key)"
				class="insights__post__gallery__image">
				<img :src="image" class="insights__post__gallery__image__image">
		</span>
			<div
				v-if="post.gallery.length > maxImagesPerGallery"
				class="insights__post__gallery__more"
				@click.prevent="showAll"
			>
				+{{ post.gallery.length - maxImagesPerGallery }}
				<span class="insights__post__gallery__more__subtext">Load More</span>
			</div>

		</div>

		<div class="insights__post__body">
			<p>{{ post.post_content }}</p>
		</div>

		<Social
			:user="user"
			:post="post"
		/>
	</article>
</template>

<script>
import Social from '@/components/TimelineComponents/TimelineArticleSocialComponent';
import Slideshow from '@/components/TimelineComponents/SlideshowComponent';
export default {
	components: {
		Social,
		Slideshow,
	},

	props: {
		post: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
	},

	data () {
		return {
			postStatus: this.post.post_status,
			showingMenu: false,
			likeCounter: this.post.likes,
			likedByUser: this.post.liked_by_user,
			maxImagesPerGallery: 7,
			imageIndex: null,
		};
	},

	methods: {
		insightClicked(item) {
			// this.$emit('openLightbox');
			this.imageIndex = item;
			gtag('event', 'click', {'event_category': 'Insights', 'event_label': 'Image Click:' + this.post.post_title});
		},

		lightboxClosed() {
			// this.$emit('closeLightbox');
			this.imageIndex = null;
		},

		showAll() {
			this.maxImagesPerGallery = 99;
		}
	},

	computed: {
		postTime() {
			return window.moment(this.post.post_date).fromNow();
		},
	}
};
</script>
