<template>
    <div v-if="editor">
      <div class="tiptap__buttons">
        <button type="button" @click="bold" class="editor-b editor-bold"  :class="{ 'is-active': editor.isActive('bold') }">
          B
        </button>

        <button type="button" @click="italic" class="editor-b editor-italic"  :class="{ 'is-active': editor.isActive('italic') }">
          I
        </button>

        <button type="button" @click="editor.chain().focus().toggleUnderline().run()" class="editor-b editor-underline"  :class="{ 'is-active': editor.isActive('underline') }">
          U
        </button>

        <button type="button" class="editor-b editor-italic"  @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="20" height="24">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
          </svg>
        </button>
      </div>
      <editor-content :editor="editor" v-model="value"/>
    </div>
</template>
  
<script>
  import Mention from '@tiptap/extension-mention'
  import Placeholder from '@tiptap/extension-placeholder'
  import Underline from '@tiptap/extension-underline'

  import StarterKit from '@tiptap/starter-kit'
  import { Editor, EditorContent } from '@tiptap/vue-2'
  
  import suggestion from './suggestion.js'
  
  export default {
    components: {
      EditorContent,
    },
  
    data() {
      return {
        editor: null,
      }
    },

    props: {
    value: {
      type: String,
      default: '',
    },
  },
  
    mounted() {
      this.editor = new Editor({
        extensions: [
          StarterKit,
          Underline,
          Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion,
          }),
          Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            placeholder: 'What would you like to share?',
          })
        ],
        content: this.value,
        onUpdate: () => {
          // HTML
          this.$emit('update', this.editor.getHTML())
        },
      })
    },

    methods: {
      bold() {
        this.editor.chain().focus().toggleBold().run()
      },

      italic() {
        this.editor.chain().focus().toggleItalic().run()
      }
    },
  
    beforeUnmount() {
      this.editor.destroy()
    },

    watch: {
      value(value) {
        const isSame = this.editor.getHTML() === value

        if (isSame) {
          return
        }

        this.$emit('update', value);

        this.editor.commands.setContent(value, false)
      },
    },
  }
</script>
  <style>

  .editor-bold {
    font-weight: bold;
  }

  .editor-italic {
    font-style: italic;
    font-family: serif;
  }
  .tiptap__buttons {
    display: flex;
    column-gap: .5rem;
    margin-bottom: 1rem;
  }

  .editor-b {
    border-radius: 5px;
    background-color: #fff;
    width: 30px;
    height: 25px;
    text-align: center;
    border: 1px solid #fff;
    padding: 0;
    font-size: 20px;
  }

  .editor-underline {
    text-decoration: underline;
    font-size: 18px;
  }

  .editor-b:hover {
    cursor: pointer;
  }

  .editor-b.is-active {
    background-color: #66b4b4;
    border-color: #66b4b4;
    color: #fff;
  }

  .tiptap strong {
    font-weight: bolder;
  }

  .tiptap em {
    font-style: italic;
  }

  .tiptap p {
    font-size: 1.1rem;
  }

  .tiptap {
    font-size: 1.4rem;
    padding: 1rem 1rem 1rem 1rem;
    background: transparent;
    width: 100%;
    color: #fff;
    box-shadow: none;
    resize: none;
    border: none;
    line-height: 1.3em;
    outline: none;
    font-size: 0.9rem;
    font-family: "Open Sans", san-serif;
    flex-grow: 1;
    height: calc(100vh - 360px);
    border: 1px solid #333;
  }

  .tiptap li {
    list-style: disc;
    margin-left: 1rem;
  }

  .tiptap 
    > * + * {
      margin-top: 0.75em;
    }
  
  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
  }

  .tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
  </style>