<template>
	<div class="home container container--no-x-pad">
		<div class="home__layout">
			<section
				:class="{ 'loading': loading }"
				class="timeline timeline--insights"
			>
				<transition name="fade">
					<svg
						v-show="loading"
						width="100"
						height="100"
						viewBox="0 0 38 38"
						xmlns="http://www.w3.org/2000/svg"
						stroke="#fff"
						class="timeline__loader"
					>
						<g
							fill="none"
							fill-rule="evenodd"
						>
							<g
								transform="translate(1 1)"
								stroke-width="2"
							>
								<circle
									stroke-opacity=".5"
									cx="18"
									cy="18"
									r="18"
								/>
								<path d="M36 18c0-9.94-8.06-18-18-18">
									<animateTransform
										attributeName="transform"
										type="rotate"
										from="0 18 18"
										to="360 18 18"
										dur="1s"
										repeatCount="indefinite"
									/>
								</path>
							</g>
						</g>
					</svg>
				</transition>

				<transition name="fade">
					<div v-if="!loading">
						<div v-if="!posts.length && !loading">
							<div class="timeline__empty">
								<h2 class="timeline__empty--title">It's very quiet</h2>
								<p class="timeline__empty--intro">There aren't any Insights available to view yet. Keep checking back for updates!</p>
							</div>
						</div>

						<div v-else-if="posts.length">
							<InsightArticle
								v-for="(post, idx) in posts"
								:post="post"
								@closeLightbox="lightboxOpen = false"
								@openLightbox="lightboxOpen = true"
								:user="currentUser"
								:key="'post-'+idx"
							/>
						</div>
					</div>
				</transition>
			</section>
		</div>
	</div>
</template>

<script>

import InsightArticle from '@/components/TimelineComponents/InsightPostComponent';
import VuePullRefresh from 'vue-pull-refresh';
import { mapState } from 'vuex';

export default {
	name: 'Insights',

	components: {
		InsightArticle,
		'vue-pull-refresh': VuePullRefresh,
	},

	data () {
		return {
			posts: [],
			loading: true,
			ended: false,
			batch: 5,
			lightboxOpen: false,
			offset: null,
			pullToRefreshConfig: {
				errorLabel: 'Error refreshing Insights',
				startLabel: 'Pull to refresh Insights',
				readyLabel: 'Release to reload',
				loadingLabel: 'Loading Insights',
				pullDownHeight: 150,
			},
		};
	},

	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
	},

	mounted () {
		this.offset = this.batch;
		this.loadInsights();
		this.getNextPage();
	},

	methods: {
		loadInsights () {
			window.axios.get('wp-json/kxi/v1/insights/?limit=' + this.batch).then(res => {
				this.posts = res.data;
				this.loading = false;
			});
		},

		async onRefresh () {
			// check if open gallery item;
			if (this.lightboxOpen) {
				return;
			}
			this.posts = [];
			this.loading = true;
			this.ended = false;
			this.offset = this.batch;

			this.loadInsights();
		},

		getNextPage () {
			window.onscroll = () => {
				let bottomOfWindow = (document.documentElement.scrollHeight - (document.documentElement.scrollTop + 200)) <= document.documentElement.clientHeight;
				if (bottomOfWindow && !this.loading && !this.ended) {
					this.loading = true;

					window.axios.get('wp-json/kxi/v1/insights/?offset=' + this.offset + '&limit=' + this.batch).then(res => {
						this.loading = false;

						if (res.data.length < 1) {
							this.ended = true;
							return;
						}

						this.offset += this.batch;
						this.posts = this.posts.concat(res.data);
					}).catch(() => {
						this.loading = false;
					});
				}
			};
		},
	},
};
</script>
