<template>
    <div class="public__profile">
        <div class="container">
            <div v-if="profile">
                <div class="public__header">
                    <div class="public__stat">
                        {{profile.post_counter}}
                        <small class="public__stat__key">Posts</small>
                    </div>
                    <img
                        v-if="profile.profile_avatar"
                        :src="profile.profile_avatar"
                        class="profile__avatar avatar-badge public__avatar avatar-badge--with-shadow"
                        :class="'avatar-badge--'+profile.rank.tier_name"
                        :style="'border-color:' + profile.rank.tier_colour+ '; box-shadow: 0 0 25px 1px '+ profile.rank.tier_colour">
                    <div class="public__stat">
                        {{ profile.comment_counter }}
                        <small class="public__stat__key">Comments</small>
                    </div>
                </div>
                <div class="public__name">
                    <h1>{{ profile.name }}</h1>
                    <div class="public__name__group">
                        <p class="public__name__since">Start Date: {{ profile.member_since }}</p>
                        <p class="public__name__since">Department: {{ profile.department }}</p>
                        <p class="public__name__since">Role: {{ profile.role }}</p>
                    </div>
                </div>


                <header class="public__heading" v-if="profile.description">
                    <span>About</span>
                </header>

                <div class="public__description" v-if="profile.description">
                    <p>{{ profile.description }}</p>
                </div>

                <header class="public__heading" v-if="profile.posts">
                    <span>Latest Posts</span>
                </header>

                <div class="last_post">
                    <TimelineArticle
						v-for="(post) in profile.posts"
						:post="post"
						:user="currentUser"
                        :simple="true"
						:key="'timeline-post-'+post.ID"></TimelineArticle>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import AuthService from '@/services/AuthService';
import TimelineArticle from '@/components/TimelineComponents/TimelineArticleComponent';

export default {
	data () {
		return {
            profile: null

		};
	},

    components: {
		TimelineArticle,
    },

	mounted () {
        this.get_user();
	},


	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
	},

    watch:{
        $route (to, from){
            if((from.params && to.params) && from.params.id !== to.params.id) {
                this.get_user();
            }
        }
    } ,

	methods: {
        get_user () {
			let id = this.$route.params.id;
			let url = 'wp-json/kxi/v1/profile/' + id;
			if (isNaN(id)) {
                // Do something if nothing here
				return;
			}
			window.axios.get(url)
				.then(res => {
					if (res.status !== 200) {
						// Show some error or 404 page
						this.$router.replace({name: 'error'});
					}

                    this.profile = res.data;

				}).catch(err => {
					// Show some error or 404 page
					console.log(err);
					this.$router.replace({name: 'error'});
				});
		},
	},
};
</script>
