<template>
	<CoolLightBox
		:items="images"
		:index="index"
		:close-on-click-outside-mobile="true"
		:loop="true"
		:use-zoom-bar="true"
		slideshow-color-bar="#67B4B4"
		overlay-color="#111111"
		@close="closeLightbox"
		@on-open="$emit('openLightbox')"
	>
		<template v-slot:close>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="w-6 h-6">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M6 18L18 6M6 6l12 12" />
			</svg>
		</template>
	</CoolLightBox>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
	components: {
		CoolLightBox,
	},

	props: ['index', 'images'],

	methods: {
		closeLightbox () {
			
			this.$emit('closeLightbox');
		},
	},
};
</script>
